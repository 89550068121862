.container {
  width: 100%;
  max-width: 54rem;
  padding: 6rem 0;
  position: absolute;
  top: 0;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media (min-width: 640px) {
  .card {
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: rgb(255, 255, 255);
    border-width: 1px;
    border-radius: 0.75rem;
    border-color: rgba(229, 231, 235, 0.5);
    box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0 0 0 / 0.1),
      0 2px 4px -2px rgb(0 0 0 / 0.1);
  }
}

.logo-wrapper {
  height: 3.5rem;
}
.logo-wrapper > img {
  width: 100%;
  height: 100%;
}

.form {
  width: 100%;
  margin-top: 3rem;
}
.form > * + * {
  margin-top: 1.5rem;
}

.input-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@media (min-width: 640px) {
  .input-group {
    flex-direction: row;
  }
}

.button {
  width: 100%;
  font-weight: 500;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  padding: 0.75rem;
  color: rgb(255, 255, 255);
  background-color: rgb(37, 99, 235);
}
.button:hover {
  background-color: rgb(59, 130, 246);
}
.button:focus {
  /* remove default outline */
  outline: 2px solid transparent;
  outline-offset: 2px;
  /* add outline with shadow */
  box-shadow: 0 0 0 calc(4px) rgba(59, 130, 246, 0.5), 0 0 #0000;
  /* transition */
  transition-property: border-color, box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.button:disabled:hover {
  background-color: rgb(37, 99, 235);
}

.text {
  color: rgb(107, 114, 128);
  text-align: center;
}

.link {
  color: rgb(37, 99, 235);
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.link:hover {
  color: rgb(59, 130, 246);
  text-decoration-line: underline;
  text-underline-offset: 1px;
}

@media (min-width: 640px) {
  .text {
    margin-top: 2rem;
  }
}

.verification-text {
  margin-top: 3rem;
  text-align: center;
}

.error-text {
  margin-top: 1rem;
  text-align: center;
  color: rgb(239, 68, 68);
}
