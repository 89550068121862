@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: rgb(243, 244, 246);
  /* set transition 500ms to everything */
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  /* line-height: 1.5; */
  /* font-family: 'Roboto', Serif, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-size: 1em;
}

button,
input {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button {
  -webkit-appearance: button;
  text-transform: none;
  cursor: pointer;
  background-image: none;
  /* background-color: bleck; */
}

button.submit {
  background-color: #3358d4 !important;
}
button.submit:hover {
  background-color: #5c79dd !important;
}

.mapboxgl-popup-content {
  font-family: Roboto;
  font-size: 16px;
  padding: 0;
}
/* .mapboxgl-popup {
  padding: 0;
  margin: 0;
} */

/* .tickerbar {
  max-width: calc(100vw - 13rem);
} */
.jobad a {
  color: #3358d4;
}
.jobad a:hover {
  color: #5c79dd;
  text-decoration: underline;
}

@media print {
  #root > div.layout.h-screen.w-screen.bg-gray-50.flex.flex-col.font-body.text-custom-black > main > div > div.dashboard-nav.h-full.w-fit.flex.flex-col.py-5.gap-0.text-lg.font-medium.bg-custom-white.border-r.border-neutral-60.z-10,
  #headlessui-tabs-panel-\:r2n\: > div.w-full.h-11.flex.flex-row.bg-neutral-color-10.text-custom-black.text-lg.font-medium,
  #headlessui-tabs-panel-\:r7n\: > div > div > div > div.bg-white.relative.shadow.rounded-lg.transition-all.duration-500.p-5.w-1\/4.h-full,
  #headlessui-switch-\:r2t\: > div.absolute.w-full.h-8.left-1.top-1.text-left
   {
    display: none;
  }

  .comment {
    /* page-break-after: always;
    clear: both; */
  }

  html, body {
    /*changing width to 100% causes huge overflow and wrap*/
    height:100% !important; 
    size: A4;
  }

  .bg-white.relative.shadow.rounded-lg.transition-all.duration-500.p-5 {
    padding-right: 6rem;
    overflow: hidden;
  }

  .h-screen, .max-h-full {
    height: 100% !important;
    max-height: 100% !important;
  }

  .overflow-visible, .overflow-scroll {
    overflow: visible !important;
  }

}