.container {
  width: 100%;
}
/* @media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
} */

.header {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  /* height: 42px; */
  /* background-color: rgb(255, 255, 255);
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1); */
  z-index: 10;
}

.logo-wrapper {
  position: relative;
  width: 6rem;
}

.menu {
  position: relative;
  z-index: 50;
  /* margin-right: 2em; */
}

.menu-button {
  display: flex;
  align-items: center;
}
.menu-button > svg {
  margin-right: 1px;
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  color: rgb(107, 114, 128);
}
.menu-button:hover > svg {
  color: currentColor;
}

.menu-transition-enter {
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 150ms;
}
.menu-transition-enter-from {
  opacity: 0;
  transform: scale(0.95, 0.95);
}
.menu-transition-enter-to {
  opacity: 1;
  transform: scale(1, 1);
}
.menu-transition-leave {
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 150ms;
}
.menu-transition-leave-from {
  opacity: 1;
  transform: scale(1, 1);
}
.menu-transition-leave-to {
  opacity: 0;
  transform: scale(0.95, 0.95);
}

.menu-items-container {
  position: absolute;
  right: 0px;
  width: 18rem;
  overflow: hidden;
  margin-top: 0.25rem;
  transform-origin: top right;
  background-color: rgb(255, 255, 255);
  border-radius: 0.375rem;

  box-shadow: 0 0 0 0px #fff, 0 0 0 calc(1px) rgb(0 0 0 / 0.05), 0 0 #0000,
    0 0 #0000, 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.menu-items-container:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.menu-items-container > * + * {
  border-top-width: 1px;
  border-color: rgb(243, 244, 246);
}

.menu-header {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
}
.menu-header > * + * {
  margin-left: 0.5rem;
}

.user-details {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 1rem;
}
.user-email {
  color: rgb(107, 114, 128);
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.menu-items {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.menu-item {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.menu-item:last-child {
  border-top-width: 1px;
  padding-top: 0.5rem;
  margin-top: 0.5rem;
}
.menu-item > a,
.menu-item > button {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.375rem;
}
.menu-item > a:hover,
.menu-item > button:hover {
  background-color: rgb(243, 244, 246);
}
.menu-item > a > svg,
.menu-item > button > svg {
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  color: rgb(107, 114, 128);
}

/* .main {
  flex: 1 1 auto;
  background-color: aliceblue;
} */

.main-container {
  composes: container;
  margin-left: auto;
  height: 100%;
  /* margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 3rem;
  padding-bottom: 3rem; */
}
